
.spectrum-Table {
  width: 100%;
}

.spectrum-Table-body .spectrum-Table-row:nth-child(2n+0) {
  background: var(--spectrum-global-color-gray-75);
}

.spectrum-Table-body .spectrum-Table-row:hover {
  background-color: var(--spectrum-table-row-background-color-hover, var(--spectrum-alias-highlight-hover));
}

.spectrum-Table-headCell {
  white-space: nowrap;
}