
.toast {
  transition-property: opacity, transform;
  opacity: 0;
  transform: translateX(-100%);
  pointer-events: auto;
  margin-top: .25rem;
}

.isActive {
  opacity: 1;
  transform: translateX(0);
}
