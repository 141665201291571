
.root {
  flex-grow: 1;
  overflow-y: auto;
}

.heading {
  font-family: 'Roboto Slab', serif;
  margin-bottom: var(--spectrum-global-dimension-size-450) !important;
}

.article {
  padding: var(--spectrum-global-dimension-size-550) var(--spectrum-global-dimension-size-550) var(--spectrum-global-dimension-size-600);
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  /* align-items: flex-start; */
  width: 967px;
  max-width: 100%;
  flex-grow: 1;
  margin: 0 0 auto;
}


.status {
  padding: 109px 52px 52px;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  /* align-items: flex-start; */
  width: 967px;
  max-width: 100%;
  flex-grow: 1;
  margin: 0 0 auto;
}
