
.table {
  margin: 1rem 0 2rem;
}

.theadcell {
  text-transform: capitalize;
  padding-left: 0;
  color: var(--spectrum-global-color-gray-800);
  /* font-size: var(--spectrum-alias-font-size-default, var(--spectrum-global-dimension-font-size-100)); */
}
