
.inputWrapper input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
}

.dialog {
  --spectrum-dialog-padding: 20px;
  width: auto;
}
