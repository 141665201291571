
.root {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  padding: var(--spectrum-global-dimension-size-350) var(--spectrum-global-dimension-size-300);
  text-decoration: none;
  cursor: default;
}

a.root {
  cursor: pointer;
}

.logo {
  height: 32px;
  width: 32px;
  margin-right: var(--spectrum-global-dimension-size-200);
  overflow: hidden;
}
