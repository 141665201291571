
.root {
  background-color: var(--spectrum-global-color-gray-50);
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.spectrum-Site-mainContent {
  flex-grow: 1;
  border-top: none;
  border-bottom: none;
  overflow-y: auto;
}

.spectrum-Site-article {
  padding: 40px 52px 0;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: flex-start;
  max-width: 967px;
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
}
