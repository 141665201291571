
.pagination {
  display: grid;
  grid-template-columns: repeat(auto-fit, min-content);
  grid-column-gap: 10px;
  grid-auto-flow: column;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  justify-content: end;
}

.spectrum-Pagination-textfield {
  width: var(--spectrum-pagination-textfield-width, var(--spectrum-global-dimension-size-600));
  min-width: var(--spectrum-pagination-textfield-width, var(--spectrum-global-dimension-size-600));
}
