
.toastPortal {
  position: fixed;
  bottom: 0;
  left: 0;
  pointer-events: none;
  padding: var(--spectrum-global-dimension-size-150) var(--spectrum-global-dimension-size-150);
}

.toastPortal > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
