
.spectrum-Site-sideBar {
  background-color: var(--spectrum-global-color-gray-75);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 256px;
  max-width: 100%;
  transition: none;
}

.spectrum-Site-nav {
  padding: 30px 24px;
  flex: 1;
  flex-grow: 1;
  flex-grow: 1;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.spectrum-SideNav-itemLink.is-selected {
  background-color: var(--spectrum-sidenav-item-background-color-down, var(--spectrum-alias-highlight-hover))
}

.spectrum-SideNav-logOut {
  padding: var(--spectrum-global-dimension-size-350) 0;
}
