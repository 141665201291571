
.is-sortable .spectrum-Table-sortedIcon {
  display: inline-block;
  opacity: 0;
  transform: rotateZ(-180deg) !important;
  margin-top: calc(var(--spectrum-global-dimension-size-25) * -1);
}

.is-sortable:hover .spectrum-Table-sortedIcon {
  opacity: 1;
}

.is-sortable.is-sorted-asc .spectrum-Table-sortedIcon {
  opacity: 1;
  transform: rotateZ(-180deg) !important;
}

.is-sortable.is-sorted-desc .spectrum-Table-sortedIcon {
  opacity: 1;
  transform: rotateZ(0deg) !important;
}
